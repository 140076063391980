/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./CustomStyle.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Header(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  // for mobile
  const [lang, setLang] = useState("id");

  return (
    <div>
      {/* <!--Start Header --> */}
      <header className="nav-bg-b main-header navfix fixed-top menu-dark">
        <div className="container-fluid m-pad">
          <div className="menu-header">
            <div className="dsk-logo">
              <Link className="nav-brand" onClick={() => history.push("/")}>
                <img
                  src="https://res.cloudinary.com/deafomwc7/image/upload/v1664837462/codespace/images/codespace/logoheaderpng/logolans_white_lmesac.png"
                  alt="Logo"
                  className="mega-white-logo"
                />
                <img
                  src="assets/images/codespace/logoheaderpng/logolans_color.png"
                  alt="Logo"
                  className="mega-darks-logo"
                />
              </Link>
            </div>
            <div className="custom-nav" role="navigation">
              <ul className="nav-list">
                <li className="sbmenu">
                  <a onClick={() => history.push("/")} className="menu-links">
                    {t("Home")}
                  </a>
                </li>

                <li className="sbmenu">
                  <a
                    onClick={() => history.push("/our-team")}
                    className="menu-links"
                  >
                    {t("OurTeam")}
                  </a>
                </li>
                <li className="sbmenu">
                  <a
                    onClick={() => history.push("/contact-us")}
                    className="menu-links"
                  >
                    {t("Contact")}
                  </a>
                </li>
                <li className="sbmenu">
                  <a
                    onClick={() => history.push("/career")}
                    className="menu-links"
                    style={{ color: "#FFC007" }}
                  >
                    {t("Careers")}
                  </a>
                </li>
                <li className="sbmenu rpdropdown">
                  <a href="#" className="menu-links">
                    {t("FlagWithTitle")}
                  </a>
                  <div className="nx-dropdown menu-dorpdown">
                    <div className="sub-menu-section">
                      <div className="sub-menu-center-block">
                        <div className="sub-menu-column smfull">
                          <div className="menuheading">Translate</div>
                          <ul>
                            <li>
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => changeLanguage("id")}
                              >
                                <span role="img" aria-label="Indonesia2">
                                  🇮🇩
                                </span>{" "}
                                Indonesia
                              </a>{" "}
                            </li>
                            <li>
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => changeLanguage("en")}
                              >
                                <span role="img" aria-label="UK2">
                                  🇬🇧
                                </span>{" "}
                                English
                              </a>{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <a href="#" className="menu-links right-bddr">
                    &nbsp;
                  </a>
                  {/* <!--menu right border--> */}
                </li>
                <li>
                  <a
                    href="https://api.whatsapp.com/send?phone=6282141866633&text=%20Halo%20admin,%20saya%20ingin%20membuat%20website/aplikasi..."
                    className="btn-br bg-btn3 btshad-b2 lnk"
                    data-umami-event="Send WA From Header"
                  >
                    {t("SendUsMessage")} <span className="circle"></span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="mobile-menu2">
              <ul className="mob-nav2">
                <li className="hidedesktop">
                  <a
                    data-bs-toggle="offcanvas"
                    href="#/"
                    className="btn-round- btn-br bg-btn-3"
                    onClick={() =>
                      lang === "id"
                        ? (changeLanguage("en"), setLang("en"))
                        : (changeLanguage("id"), setLang("id"))
                    }
                  >
                    {/* <i class="fas fa-phone-alt"></i> */}
                    <i>{t("ToOther")}</i>
                    {/* <a href="#" className="menu-links">
                      {t("FlagWithTitle")}
                    </a> */}
                  </a>
                </li>
                <li className="navm-">
                  <a className="toggle" href="#">
                    <span></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <!--Mobile Menu--> */}
          <nav id="main-nav">
            <ul className="first-nav">
              <li>
                <a href="/">{t("Home")}</a>
              </li>
              <li>
                <a href="/about-us">{t("AboutUs")}</a>
              </li>
              <li>
                <a href="/our-team">{t("OurTeam")}</a>
              </li>
              <li>
                <a href="/contact-us">{t("Contact")}</a>
              </li>
            </ul>
            <ul className="bottom-nav">
              <li className="prb">
                <a href="tel:+6282141866633">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384">
                    <path
                      d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z"
                    />
                  </svg>
                </a>
              </li>
              <li className="prb">
                <a href="mailto:mail@codespace.id">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      {/* <!--Mobile contact--> */}
      <div className="popup-modal1">
        <div className="modal" id="menu-popup">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="common-heading">
                  <h4 className="mt0 mb0">Write a Message</h4>
                </div>
                <button type="button" className="closes" data-dismiss="modal">
                  &times;
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="modal-body">
                <div className="form-block fdgn2 mt10 mb10">
                  <form action="#" method="post" name="feedback-form">
                    <div className="fieldsets row">
                      <div className="col-md-12">
                        <input
                          type="text"
                          placeholder="Full Name"
                          name="name"
                        />
                      </div>
                      <div className="col-md-12">
                        <input
                          type="email"
                          placeholder="Email Address"
                          name="email"
                        />
                      </div>
                      <div className="col-md-12">
                        <input
                          type="number"
                          placeholder="Contact Number"
                          name="phone"
                        />
                      </div>
                      <div className="col-md-12">
                        <input
                          type="text"
                          placeholder="Subject"
                          name="subject"
                        />
                      </div>
                      <div className="col-md-12">
                        <textarea
                          placeholder="Message"
                          name="message"
                        ></textarea>
                      </div>
                    </div>
                    <div className="fieldsets mt20 pb20">
                      <button
                        type="submit"
                        name="submit"
                        className="lnk btn-main bg-btn"
                        data-dismiss="modal"
                      >
                        Submit <i className="fas fa-chevron-right fa-icon"></i>
                        <span className="circle"></span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Mobile contact--> */}

      {/* <!--End Header --> */}
    </div>
  );
}
