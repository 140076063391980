/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function Careers(props) {
  return (
    <section className="team bg-gradient99">
      <div class="container">
        <div class="row upset ovr-bg2 bd-hor">
          <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
            <div
              class="s-block up-hor bd-hor-base"
              style={{ textAlign: "left" }}
            >
              <div class="nn-card-set">
                <h3 style={{ color: "#FFC007" }}>Frontend Developer</h3>
                <br />
                <h6>Job: Frontend Developer</h6>
                <h6>Grade: Middle - Seniors</h6>
                <h6>Experience: 2 Years+</h6>
                <h6>Employee Type: Fulltime, Project Based</h6>
                <br />

                <h4>Description</h4>
                <p>
                  Kami mencari Frontend Developer berpengalaman. Untuk join pada
                  salah satu projek kami.
                </p>
                <br />

                <h4>Requirements</h4>
                <ul class="list-style-">
                  <li>Pengalaman minimal 2 tahun sebagai Frontend Developer</li>
                  <li>Diutamakan menguasai ReactJS dengan Vite</li>
                  <li>
                    Memahami konsep state management (Redux/Zustand/Recoil)
                  </li>
                  <li>
                    Berpengalaman dengan Tailwind CSS atau framework styling
                    lainnya
                  </li>
                  <li>Terbiasa menggunakan Git dan metode Agile/Scrum</li>
                  <li>Memahami dasar-dasar SEO dan web accessibility</li>
                  <li>
                    Berpengalaman dalam pengaplikasian Micro Interaction, CSS
                    Animation, GSAP, Framer Motion, atau library animasi lainnya
                  </li>
                </ul>
                <br />

                <h4>Responsibility</h4>
                <ul class="list-style-">
                  <li>
                    Mengembangkan tampilan dan pengalaman pengguna menggunakan
                    ReactJS dengan Vite
                  </li>
                  <li>
                    Bekerja sama dengan tim backend untuk mengintegrasikan API
                  </li>
                  <li>
                    Mengoptimalkan performa aplikasi agar cepat dan responsif
                  </li>
                  <li>Menerapkan UI/UX yang menarik dan user-friendly</li>
                  <li>
                    Memastikan kompatibilitas aplikasi dengan berbagai perangkat
                    dan browser
                  </li>
                  <li>
                    Menulis kode yang bersih, terstruktur, dan mudah dipelihara
                  </li>
                </ul>
                <br />

                <h4>Benefits</h4>
                <ul class="list-style-">
                  <li>
                    Work From Anywhere – fleksibilitas kerja sesuai gaya hidupmu
                  </li>
                  <li>
                    Tim yang suportif & kolaboratif – belajar dan berkembang
                    bersama
                  </li>
                  <li>
                    Fokus pada hasil, bukan jam kerja – kualitas lebih utama
                  </li>
                </ul>

                {/* <div style={{ textAlign: "right" }}>
                  <a
                    href="https://docs.google.com/forms/d/11TOWEHGiOleAP_MUqFRhvXyT3EGGRiiSAl-UgzW9qlQ/viewform"
                    rel="noopener noreferrer"
                    style={{ color: "#FFC007", textAlign: "right" }}
                    target="_blank"
                  >
                    Apply <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div> */}
                <div style={{ textAlign: "right" }}>
                  <a
                    href="javascript:void(0)"
                    rel="noopener noreferrer"
                    style={{ color: "grey", textAlign: "right" }}
                  >
                    Closed <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
            <div
              class="s-block up-hor bd-hor-base"
              style={{ textAlign: "left" }}
            >
              <div class="nn-card-set">
                <h3 style={{ color: "#FFC007" }}>Fullstack Developer</h3>
                <br />
                <h6>Job: Fullstack Developer</h6>
                <h6>Grade: Middle - Seniors</h6>
                <h6>Experience: 2 Years+</h6>
                <h6>Employee Type: Fulltime, Project Based</h6>
                <br />

                <h4>Description</h4>
                <p>
                  Kami mencari Fullstack Developer berpengalaman. Untuk join
                  pada salah satu projek kami.
                </p>
                <br />

                <h4>Requirements</h4>
                <ul class="list-style-">
                  <li>
                    Pengalaman minimal 2 tahun sebagai Fullstack Developer
                  </li>
                  <li>
                    Menguasai Laravel untuk backend dan ReactJS (Vite) untuk
                    frontend
                  </li>
                  <li>
                    Berpengalaman dengan database relational seperti
                    MySQL/PostgreSQL
                  </li>
                  <li>
                    Mengerti konsep state management (Redux/Zustand) dan API
                    handling
                  </li>
                  <li>
                    Terbiasa menggunakan Git serta bekerja dalam metode
                    Agile/Scrum
                  </li>
                  <li>
                    Memahami dasar keamanan aplikasi dan best practices dalam
                    development
                  </li>
                  <li>
                    Memiliki pemahaman yang baik tentang slicing dan mampu
                    menerjemahkan desain dari Figma atau tools serupa ke dalam
                    kode dengan presisi
                  </li>
                </ul>
                <br />

                <h4>Responsibility</h4>
                <ul class="list-style-">
                  <li>
                    Mengembangkan fitur dan solusi end-to-end menggunakan
                    Laravel untuk backend dan ReactJS (Vite) untuk frontend
                  </li>
                  <li>
                    Mengelola dan merancang database untuk mendukung performa
                    aplikasi
                  </li>
                  <li>
                    Berkoordinasi dengan tim desain dan produk untuk
                    menghadirkan solusi terbaik
                  </li>
                  <li>
                    Mengoptimalkan performa aplikasi baik dari sisi frontend
                    maupun backen
                  </li>
                  <li>Menulis kode yang clean, maintainable, dan scalable</li>
                  <li>
                    Mengimplementasikan API dan memastikan integrasi yang
                    seamless antara frontend dan backend
                  </li>
                </ul>
                <br />

                <h4>Benefits</h4>
                <ul class="list-style-">
                  <li>
                    Work From Anywhere – fleksibilitas penuh sesuai kebutuhanmu
                  </li>
                  <li>Kolaborasi dalam tim yang dinamis & inovatif</li>
                  <li>
                    Fokus pada hasil, bukan jam kerja – yang penting kualitas!
                  </li>
                </ul>

                {/* <div style={{ textAlign: "right" }}>
                  <a
                    href="https://docs.google.com/forms/d/11TOWEHGiOleAP_MUqFRhvXyT3EGGRiiSAl-UgzW9qlQ/viewform"
                    rel="noopener noreferrer"
                    style={{ color: "#FFC007", textAlign: "right" }}
                    target="_blank"
                  >
                    Apply <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div> */}
                <div style={{ textAlign: "right" }}>
                  <a
                    href="javascript:void(0)"
                    rel="noopener noreferrer"
                    style={{ color: "grey", textAlign: "right" }}
                  >
                    Closed <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
            <div
              class="s-block up-hor bd-hor-base"
              style={{ textAlign: "left" }}
            >
              <div class="nn-card-set">
                <h3 style={{ color: "#FFC007" }}>Backend Developer</h3>
                <br />
                <h6>Job: Backend Developer</h6>
                <h6>Grade: Middle - Seniors</h6>
                <h6>Experience: 2 Years+</h6>
                <h6>Employee Type: Fulltime, Project Based</h6>
                <br />

                <h4>Description</h4>
                <p>
                  Kami mencari Backend Developer berpengalaman. Untuk join pada
                  salah satu projek kami.
                </p>
                <br />

                <h4>Requirements</h4>
                <ul class="list-style-">
                  <li>Pengalaman minimal 2 tahun sebagai Backend Developer</li>
                  <li>Diutamakan menguasai Laravel secara mendalam</li>
                  <li>
                    Memiliki pengalaman dalam mengembangkan dan mengelola API
                  </li>
                  <li>
                    Menguasai database relational seperti MySQL/PostgreSQL
                  </li>
                  <li>
                    Pengalaman dengan Docker, CI/CD, dan manajemen server
                    menjadi nilai tambah
                  </li>
                  <li>
                    Paham konsep security seperti OAuth, JWT, dan enkripsi data
                  </li>
                  <li>Terbiasa bekerja dengan Git dan metode Agile/Scrum</li>
                </ul>
                <br />

                <h4>Responsibility</h4>
                <ul class="list-style-">
                  <li>
                    Mengembangkan dan mengoptimalkan backend aplikasi
                    menggunakan Laravel
                  </li>
                  <li>
                    Merancang dan mengelola database (MySQL/PostgreSQL) untuk
                    memastikan efisiensi dan keamanan data
                  </li>
                  <li>
                    Membangun dan mengelola REST API yang scalable dan
                    well-documented
                  </li>
                  <li>
                    Berkoordinasi dengan tim frontend untuk memastikan integrasi
                    yang optimal
                  </li>
                  <li>
                    Menganalisis dan memperbaiki bug atau masalah performa dalam
                    sistem backend
                  </li>
                  <li>Sertifikasi PMP, CSM, atau PRINCE2 jadi nilai tambah.</li>
                  <li>
                    Menerapkan standar keamanan dan best practices dalam
                    pengembangan backend
                  </li>
                </ul>
                <br />

                <h4>Benefits</h4>
                <ul class="list-style-">
                  <li>Work From Anywhere – fleksibel tanpa batasan lokasi</li>
                  <li>
                    Tim dinamis & inovatif – kolaborasi dengan tim yang suportif
                  </li>
                  <li>
                    Hasil lebih penting daripada jam kerja – fokus pada kualitas
                    & delivery
                  </li>
                </ul>

                {/* <div style={{ textAlign: "right" }}>
                  <a
                    href="https://docs.google.com/forms/d/11TOWEHGiOleAP_MUqFRhvXyT3EGGRiiSAl-UgzW9qlQ/viewform"
                    rel="noopener noreferrer"
                    style={{ color: "#FFC007", textAlign: "right" }}
                    target="_blank"
                  >
                    Apply <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div> */}
                <div style={{ textAlign: "right" }}>
                  <a
                    href="javascript:void(0)"
                    rel="noopener noreferrer"
                    style={{ color: "grey", textAlign: "right" }}
                  >
                    Closed <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
            <div
              class="s-block up-hor bd-hor-base"
              style={{ textAlign: "left" }}
            >
              <div class="nn-card-set">
                <h3 style={{ color: "#FFC007" }}>Quality Assurance (QA)</h3>
                <br />
                <h6>Job: Quality Assurance (QA) Engineer</h6>
                <h6>Grade: Middle - Seniors</h6>
                <h6>Experience: 2 Years+</h6>
                <h6>Employee Type: Fulltime, Project Based</h6>
                <br />

                <h4>Description</h4>
                <p>
                  Kami mencari Quality Assurance (QA) Engineer berpengalaman.
                  Untuk join pada salah satu projek kami.
                </p>
                <br />

                <h4>Requirements</h4>
                <ul class="list-style-">
                  <li>Pengalaman minimal 2 tahun sebagai QA Engineer</li>
                  <li>Memahami konsep manual testing dan automated testing</li>
                  <li>
                    Berpengalaman menggunakan tools seperti Selenium, JMeter,
                    Postman, atau Cypress
                  </li>
                  <li>
                    Mampu menulis skrip pengujian menggunakan JavaScript/Python
                    atau bahasa terkait
                  </li>
                  <li>
                    Familiar dengan API testing, load testing, dan security
                    testing
                  </li>
                  <li>Terbiasa bekerja dalam lingkungan Agile/Scrum</li>
                  <li>
                    Memiliki perhatian tinggi terhadap detail dan kemampuan
                    analisis yang baik
                  </li>
                </ul>
                <br />

                <h4>Responsibility</h4>
                <ul class="list-style-">
                  <li>
                    Menguji dan memastikan kualitas perangkat lunak sebelum
                    dirilis
                  </li>
                  <li>
                    Membuat dan menjalankan test plan, test case, serta test
                    script
                  </li>
                  <li>
                    Melakukan manual testing dan automated testing untuk
                    memastikan performa aplikasi
                  </li>
                  <li>
                    Berkoordinasi dengan tim developer untuk mendeteksi,
                    mendokumentasikan, dan menyelesaikan bug
                  </li>
                  <li>
                    Memastikan aplikasi bekerja dengan baik di berbagai platform
                    dan perangkat
                  </li>
                  <li>
                    Mengembangkan serta menerapkan strategi QA untuk
                    meningkatkan efisiensi testing
                  </li>
                </ul>
                <br />

                <h4>Benefits</h4>
                <ul class="list-style-">
                  <li>
                    Work From Anywhere – fleksibilitas kerja sesuai kebutuhanmu
                  </li>
                  <li>Kolaborasi dalam tim yang dinamis & inovatif</li>
                  <li>Fokus pada hasil, yang penting kualitas!</li>
                </ul>

                {/* <div style={{ textAlign: "right" }}>
                  <a
                    href="https://docs.google.com/forms/d/11TOWEHGiOleAP_MUqFRhvXyT3EGGRiiSAl-UgzW9qlQ/viewform"
                    rel="noopener noreferrer"
                    style={{ color: "#FFC007", textAlign: "right" }}
                    target="_blank"
                  >
                    Apply <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div> */}
                <div style={{ textAlign: "right" }}>
                  <a
                    href="javascript:void(0)"
                    rel="noopener noreferrer"
                    style={{ color: "grey", textAlign: "right" }}
                  >
                    Closed <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
